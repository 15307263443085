import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import Moment from "moment"
import Layout from "../../components/layout"
import CTABlock from "../../components/blocks/cta_block"
import GoogleMap from "../../components/maps"
Moment.locale("en")

const FindClubDetailPage = ({ data }) => {
  const page = data.wagtail.page
  let ancestor = { children: [], button: {} }
  page.ancestors.forEach(item => {
    if ("children" in item) {
      ancestor = item
    }
  })

  return (
    <Layout
      isContent={true}
      subMenu={ancestor.children}
      button={ancestor.button}
      parent={"clubs"}
      siteTitle={"Find A Club"}
    >
      <section className="breadcrumb-area breadcrumb-sub-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb nav-breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/clubs">Clubs</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={`/clubs/${page.ancestors[0].slug}`}>
                      {page.ancestors[0].title}
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-area event">
        <div className="tab-content-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <div className="contact">
                      <h1>{page.name || page.university}</h1>
                      <div
                        className="c-title-content"
                        dangerouslySetInnerHTML={{
                          __html: page.bodySafe,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-7">
                    <div className="evrnt-logation-contact">
                      {page.abbreviation && (
                        <>
                          <h3
                            className="subtitle-contact"
                            style={{ width: "auto", marginBottom: "0px" }}
                          >
                            abbreviation
                          </h3>
                          <p className="extra">{page.abbreviation}</p>
                          <br />
                        </>
                      )}
                      {page.region && (
                        <>
                          <h3
                            className="subtitle-contact"
                            style={{ width: "auto", marginBottom: "0px" }}
                          >
                            region
                          </h3>
                          <p className="extra">{page.region}</p>
                          <br />
                        </>
                      )}
                      {page.website && (
                        <>
                          <h3
                            className="subtitle-contact"
                            style={{ width: "auto", marginBottom: "0px" }}
                          >
                            website
                          </h3>
                          <p className="extra">
                            <a
                              href={`http://${page.website}`}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              {page.website}
                            </a>
                          </p>
                          <br />
                        </>
                      )}
                      {(page.description || page.phone || page.email) && (
                        <>
                          <h3
                            className="subtitle-contact"
                            style={{ width: "auto", marginBottom: "0px" }}
                          >
                            additional information
                          </h3>
                          <p className="extra">{page.description}</p>
                          <p className="extra">
                            {page.phone !== "()" ? page.phone : ""}
                          </p>
                          <p className="extra">
                            <a
                              href={`mailto:${page.email}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {page.email}
                            </a>
                          </p>
                          <br />
                        </>
                      )}
                      <h3
                        className="subtitle-contact"
                        style={{ marginBottom: "0px" }}
                      >
                        Club Representatives
                      </h3>
                      {(page.presidentfirstname ||
                        page.presidentlastname ||
                        page.presidentphone ||
                        page.presidentemail) && (
                        <div>
                          <p className="name" style={{ marginTop: "0px" }}>
                            {page.presidentfirstname}{" "}
                            {page.presidentfirstname && " "}{" "}
                            {page.presidentlastname}
                          </p>
                          <p className="title">Club President</p>
                          <p className="extra">
                            {page.presidentphone !== "()"
                              ? page.presidentphone
                              : ""}
                          </p>
                          <p className="extra">
                            <a
                              href={`mailto:${page.presidentemail}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {page.presidentemail}
                            </a>
                          </p>
                          <br />
                        </div>
                      )}
                      {(page.headcoachfirstname ||
                        page.headcoachlastname ||
                        page.headcoachphone ||
                        page.headcoachemail) && (
                        <div>
                          <p className="name" style={{ marginTop: "0px" }}>
                            {page.headcoachfirstname}{" "}
                            {page.headcoachfirstname && " "}{" "}
                            {page.headcoachlastname}
                          </p>
                          <p className="title">Head Coach</p>
                          <p className="extra">
                            {page.headcoachphone !== "()"
                              ? page.headcoachphone
                              : ""}
                          </p>
                          <p className="extra">
                            <a
                              href={`mailto:${page.headcoachemail}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {page.headcoachemail}
                            </a>
                          </p>
                          <br />
                        </div>
                      )}
                      {(page.clubfirstname ||
                        page.clublastname ||
                        page.clubphone ||
                        page.email) && (
                        <div>
                          <p className="name" style={{ marginTop: "0px" }}>
                            {page.clubfirstname} {page.clubfirstname && " "}{" "}
                            {page.clublastname}
                          </p>
                          <p className="title">Club Official Chair</p>
                          <p className="extra">
                            {page.clubphone !== "()" ? page.clubphone : ""}
                          </p>
                          <p className="extra">
                            <a
                              href={`mailto:${page.clubemail}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {page.clubemail}
                            </a>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-5">
                    {!page.extra && (
                      <div className="map">
                        <a
                          href={`https://maps.google.com?near=${page.address}`}
                          target="_blank"
                        >
                          <img src={`https://admin.swimontario.com/${page.imageMaps}`} />
                        </a>
                      </div>
                    )}
                    <div
                      className={
                        !page.extra
                          ? "evrnt-logation-contact evrnt-map"
                          : "evrnt-logation-contact"
                      }
                    >
                      <div>
                        {(!page.extra ||
                          page.extra.toLowerCase() === "webinar") &&
                          (page.address ||
                            page.address2 ||
                            page.postalcode ||
                            page.city) && (
                            <h3
                              className="subtitle-contact"
                              style={{ width: "auto", marginBottom: "0px" }}
                            >
                              primary pool
                            </h3>
                          )}
                        <p className="extra">{page.address2 ?? "-"}</p>
                        {page.city && <p className="extra">{page.city}</p>}
                        {page.address && (
                          <p className="extra">{page.address}</p>
                        )}
                        {page.postalcode && (
                          <p className="extra">{page.postalcode}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {data.wagtail.page.fullContent &&
        data.wagtail.page.fullContent.length > 0 && (
          <CTABlock data={data.wagtail.page.fullContent[0]} />
        )}
    </Layout>
  )
}

FindClubDetailPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query($id: Int) {
    wagtail {
      page(id: $id) {
        ... on ItemClub {
          name
          abbreviation
          region
          website
          description
          address
          address2
          imageMaps
          city
          postalcode
          phone
          email
          university
          extra
          presidentfirstname
          presidentlastname
          presidentphone
          presidentemail
          headcoachfirstname
          headcoachlastname
          headcoachphone
          headcoachemail
          clubfirstname
          clublastname
          clubphone
          clubemail
          isProtected
          fullContent {
            ... on CTABlock {
              id
              title
              image {
                ... on ImageObjectType {
                  src
                  file
                  width
                  height
                }
              }
              description
              orientation
              url
              urlName
            }
          }
          ancestors(order: "-depth") {
            slug
            title
            ... on ClubsPage {
              children {
                title
                slug
                ... on SimplePage {
                  live
                  isProtected
                }
                ... on FindClub {
                  live
                  isProtected
                }
              }
              button {
                text
                name
                url
              }
            }
          }
        }
      }
    }
  }
`
export default FindClubDetailPage
